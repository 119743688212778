<ng-container *ngIf="alertMsg.msg !== ''">
    <app-alert [message]="alertMsg"></app-alert>
</ng-container>

<div class="continer-fluid">
    <div class="new-dashboard-wrapper">   
        <!-- app header -->
        <app-new-header (ngoChanged)="ngoChanged($event)"></app-new-header>
        <!-- Side Menu -->
        <app-side-menu></app-side-menu>

        <div class="grid-wrapper">
            <div class="row no-gutters">
                <div class="col-12">
                    <div class="heading_wrapper" id="reportHeading">
                        <div class="row no-gutters mb-2">
                            <div class="col-12 col-md-6">
                                <div class="page-title"><span [matTooltipPosition]="positionTop.value" [matTooltipClass]="positionTop.value" matTooltip="This is the home for your saved reports. They can be accessed by anyone at your organization who logs into the webapp.">Reporting</span>
                                <p class="descTitle">Create, manage, and export fully customizable reports</p></div>
                            </div>
                            <div class="col-12 col-md-6 text-right">
                                <ul class="topLinkList">                                 
                                    <li *ngIf="reportIds.length > 0">
                                        <button class="mono-dark-filled-md-btn-text" [matTooltipClass]="positionBottom.value" matTooltip="Clicking this will delete the selected report(s)" (click)="deleteSelectedReport()" type="button">Delete Selected</button>
                                    </li> 
                                    <li *ngIf="reportIds.length > 0">
                                        <button
                                            class="accent-filled-md-btn-text"
                                            type="button"
                                            [matTooltipPosition]="positionTop.value"
                                            matTooltip="Clicking this button will export a CSV file for each of your saved reports."
                                            [matTooltipClass]="positionTop.value"
                                            (click)="exportSelected()"
                                            data-toggle="modal" #modalCloseBtnRange
                                            data-target="#exportSelectedModal">Export Selected</button>
                                    </li>
                                    <li>
                                       <button (click)="openNewReport()" class="accent-filled-md-btn-iconText" [matTooltipClass]="positionBottom.value"
                                        matTooltip="Clicking this button will bring up the report builder screen." type="button"><mat-icon>add</mat-icon> New Report</button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="row no-gutters mb-3">
                            <div class="col-12 col-md-12">                                 
                                <div class="searchBox"> 
                                        <input
                                            #input
                                            type="search"
                                            class="searchText"
                                            [matTooltipPosition]="positionTop.value"
                                            [matTooltipClass]="positionTop.value"
                                            matTooltip="Type something from a saved report in this search box to quickly find the report you're looking for"
                                            placeholder="Search"
                                            (input)="searchReports(input.value)">
                                        <span class="searchIcon"></span>
                                </div>
                            </div>                 
                        </div>
                    </div>
                    
                    <div class="overlay__inner custom-overlay" *ngIf="reportLoader">
                        <div class="overlay__content">
                            <span class="spinner loadImg"></span>
                        </div>
                    </div>

                    <div class="overlay__inner custom-overlay" *ngIf="deleteLoader">
                        <div class="overlay__content" style="top: 20%;">
                            <span class="spinner loadImg"></span>
                        </div>
                    </div>

                    <!-- <div class="row custom-row mb-4"> -->
                    <div
                        class="row Report-Grid mb-4"
                        infinite-scroll
                        [infiniteScrollDistance]="1"
                        [infiniteScrollThrottle]="150"
                        (scrolled)="onScrollDown()"
                        >
                        <div
                            class="Report-Grid-item reportCard"
                            [matTooltipPosition]="positionTop.value" 
                            matTooltip="Double-click this report card to edit the report."
                            [matTooltipClass]="positionTop.value"
                            *ngFor="let report of savedReports; let i = index"
                            [ngClass]="{'isChecked': report.isChecked}"
                            (click)="isCheckedBox(i, report)"
                            (dblclick)="doubleClick(report.report_id, report)"
                            > 
                            <div class="card-content" (contextmenu)="onContextMenu($event, {content:report})">
                                <label [for]="i" class="Report-Grid-label">
                                    <h5 matTooltip="This is the title of the saved report" [matTooltipClass]="positionBottom.value">{{ report.report_name }}</h5>
                                    <div class="dots" (click)="clikedContextMenu($event, {content:report})">
                                        <img #contextMenuOption  src="../../../assets/images/dotIco.svg">
                                    </div>
                                    <div class="wrapperType">
                                        <div class="commonType" [matTooltipPosition]="positionTop.value" [matTooltipClass]="positionTop.value"  matTooltip="This indicates whether the report is a &quot;Supporters&quot; / &quot;Challenge&quot; report">Type:</div>
                                        <div class="typeValue">{{ report.report_type | titlecase }}</div>
                                    </div>
                                    <div class="wrapperType filters">
                                        <div class="commonType" [matTooltipPosition]="positionTop.value" [matTooltipClass]="positionTop.value"  matTooltip="This indicates which filters were chosen when building the report.">Filters:</div>
                                        <p class="filterList">
                                            <ng-container *ngIf="report.filter1 == 'event_participant'; else defaultFilter">
                                                Challenge Participant
                                                </ng-container>
                                                <ng-template #defaultFilter>
                                                    {{ report.filter1 }}
                                                </ng-template>
                                        </p>
                                    </div>
                                    <div class="wrapperType">
                                        <div class="commonType">Date:</div>
                                        <div class="typeValue">{{report?.from_date | date:'MM-dd-y'}} {{report?.from_date ?' to ':'None Selected' }} {{ report?.to_date | date:'MM-dd-y'}}</div>
                                    </div>
                                    <div class="wrapperType">
                                        <div class="updateTime">{{ 'Edited ' + ((report.dateDiff + ' Ago') | lowercase) }}</div>
                                    </div>
                                </label>
                            </div>                          
                            <div style="visibility: hidden; position: fixed" [style.left]="contextMenuPosition.x"
                                [style.top]="contextMenuPosition.y" [matMenuTriggerFor]="contextMenu">
                            </div>
                            <mat-menu #contextMenu="matMenu">
                                <!-- <ng-template matMenuContent let-report="report"> -->
                                    <ng-template matMenuContent let-report="report" >
                                    <button mat-menu-item type="button" (click)="openNewReport(report?.content?.report_id)">Edit</button>
                                    <button mat-menu-item (click)="exportSelected()" #modalCloseBtnRange  data-toggle="modal" data-target="#exportSelectedModal">Export</button>  
                                    <button mat-menu-item (click)="onContextMenuDuplicate(report)">Duplicate</button>                                 
                                    <button mat-menu-item (click)="openDeleteConfirmDialog(report)">Delete</button> 
                                    </ng-template>  
                                <!-- </ng-template> -->
                            </mat-menu>
                        </div>
                         <div class="Report-Grid-item newRC" (click)="openNewReport()" [matTooltipPosition]="positionTop.value" [matTooltipClass]="positionTop.value" matTooltip="Clicking this button will bring up the report builder screen." >                                
                            <div class="card-content">
                                <button class="accent-filled-md-btn-icon">
                                    <img src="assets/images/outline-circle-right.svg" class="outline-circle-right">
                                </button>
                                <div class="title">Add New</div>
                            </div>
                         </div>
                    </div>
                  
                    <!-- <div class="box noMoreReport text-center" *ngIf="savedReports.length > 0">
                        <div class="crp">No more reports to show</div>
                    </div> -->

                </div>
            </div>
        </div>
    </div>
</div>


<!-- Export Selected Reports Modal - STARTS -->
<div class="modal above fade exportDatePicker" id="exportSelectedModal"  #closeOpenCalendar role="dialog">
    <div class="modal-dialog modal-dialog-centered custom-dialog-modal modal-lg" role="document">
        <div class="modal-content m-2" (click)="stopCloseCalendarExport($event)">
         
            <div class="modal-body">
                <div class="form-wrapper">
                    <!-- For Table Data -->
                    <div class="row no-gutters exportCalendar"> 
                        <div class="col-12">
                            <button
                                type="button"                               
                                class="closeDatePicker close"
                                data-dismiss="modal" (click)="closeCalendarModal()">&times;</button>
                            <h2 class="mb-4">Export Selected Reports</h2>
                        </div>
                        <div class="dRangeBox BSDatePicker">
                            <div class="from common">
                                <div class="exportDateTime">{{startDate | date:'dd-MM-y' }} to {{endDate | date:'dd-MM-y'}}</div>
                                <span class="from"></span>
                            </div>
                            <div *ngIf="reportIds?.length<=1" class="button-wrapper"><button type="button" class="accent-filled-md-btn-text" (click)="homeReportExportAllData()" >All Time</button></div>
                            <bs-daterangepicker-inline 
                                    [bsValue]="bsInlineRangeValue"
                                    (bsValueChange)="ngSelectedReportDateChange($event)"
                                    [maxDate]="bsMaxDate"
                                    [bsConfig]="{ showWeekNumbers: false }">
                            </bs-daterangepicker-inline>
                            <div class="button-wrapper exportBtn exportPos">
                                <button type="button" class="accent-filled-md-btn-text" (click)="onExportMulti()">
                                  Export
                                </button>
                            </div>
                         </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Export Selected Reports Modal - ENDS -->
