import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Inject, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Chart } from 'chart.js';
import { NgoSharedService } from 'src/app/shared/services/ngo-shared.service';
import { FilterSharedService } from 'src/app/shared/services/filter-shared.service';
import { ChartsService } from 'src/app/services/charts.service';
import { Subscription } from 'rxjs';
import { SelectedNgo } from 'src/app/models/SelectedNgo.model';
import { Filter } from 'src/app/models/Fundraiser';
import { finalize } from 'rxjs/operators';
import { EventService } from 'src/app/services/event.service';
import { EventList, ChartColor, EventSubscriberList, FilterApplied, ChartColorLine, ChartLegends, ChartOrder } from 'src/app/models/Event';
import * as momentJs from 'moment';
import { FormatNumber } from 'src/app/shared/custom/FormatNumber';
import { UserSharedService } from 'src/app/shared/services/user-shared.service';
import { Sort } from '@angular/material/sort';
import { UserDetail } from 'src/app/models/Common';
import { DOCUMENT } from '@angular/common';
import {UntypedFormControl} from '@angular/forms';
import {TooltipPosition} from '@angular/material/tooltip';

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss']
})
export class EventListComponent implements OnInit, OnDestroy {
  @ViewChild('modalCloseBtnYear') modalCloseBtnYear: ElementRef<HTMLElement>;
  @ViewChild('modalCloseBtnMonth') modalCloseBtnMonth: ElementRef<HTMLElement>;
  @HostListener('window:resize', ['$event']) onWindowResize(event) {
    this.updateChartConfig();
  }

  public selectedNgo: SelectedNgo;
  public charityId: string;
  public charityIDSubscription: Subscription;
  public chart;
  public chartColor       = ChartColor;
  public chartColorLine   = ChartColorLine;
  public chartLegends     = ChartLegends;
  public chartOrder       = ChartOrder;
  public chartData: any[] = [];

  public eventSeriesGraphData: EventList;
  public eventSubscriberListData: EventSubscriberList[] = [];

  public currentYear  = new Date().getFullYear();
  public currentMonth = momentJs().month() + 1;

  public filterYear   = Array.from({length: this.currentYear - 2017}, (v, k) => this.currentYear - k);
  public filterMonth  = momentJs.monthsShort();

  public filterData: Filter;
  public filterDataSubscription: Subscription;
  public filterType: any;
  public filterApplied = FilterApplied;

  public fundraiserLoader   = true;
  public activeSubscriberLoader = true;
  public eventListingLoader = true;
  public eventListingData: any[] = [];

  public formatNumber = new FormatNumber();
  public user: UserDetail;
  public roles: any;
  public userRole: any;
  public userSubscription: Subscription;

  public sortingField: string;
  public orderBy: string;

  public config: any;
  public totalRecords: number;
  public currentPageCount: number;
  public datasetFlag = {
    'Total Campaigns': true,
    'Total Donations': true,
  };

  public datasets = {
    'Total Campaigns': null,
    'Total Donations': null,
  };

  public avgCampaign = 0;
  public avgDonation = 0;
  public avgDonations = 0;
  public currencyName: string;

  positionOptions: TooltipPosition[] = ['after', 'before', 'above', 'below', 'left', 'right'];
  positionLeft = new UntypedFormControl(this.positionOptions[4]);
  positionTop = new UntypedFormControl(this.positionOptions[2]); 
  positionRight = new UntypedFormControl(this.positionOptions[5]);
  positionBottom = new UntypedFormControl(this.positionOptions[3]);
  public alertMsg = { type: '', msg: ''};
  eventSubscriptionData:Subscription;
  checkedViewGraph = [];
  removeData = [];  

  constructor(
    private router: Router,
    private _ngoSharedService: NgoSharedService,
    private _filterSharedService: FilterSharedService,
    private _eventService: EventService,
    private _chartService: ChartsService,
    private _userSharedService: UserSharedService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.config = {
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: this.totalRecords
    };
    // console.log(this.filterData);
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (document.body.scrollTop > 30 ||     
    document.documentElement.scrollTop > 100) {
      document.getElementById('subscriberList').classList.add('sideContent');
    }else{
      document.getElementById('subscriberList').classList.remove('sideContent');
    }
  }

  ngOnInit() {
    this.getUserinfo();
    this.getCharityId();
  }

  getUserinfo() {
    this.userSubscription = this._userSharedService
        .userData
        .subscribe(
          res => {
            if (Object.keys(res).length > 0) {
               this.user = res as UserDetail;
               this.roles = this.user.roles;
               this.userRole = this.roles[0].role;
            }
          }
        );
  }

  getEventSeriesId(ev) {   
    this.router.navigate(['/events/series', ev.series_id]);
  }

  ngoChanged(ngo: SelectedNgo) {
    this.eventSeriesGraphData = null;
  }

  getCharityId(): void {
    this.charityIDSubscription = this._ngoSharedService
        .selectedNgo
        .subscribe(
          selectedNGO => {
            if (selectedNGO.charityId !== null) {
              this.currencyName = selectedNGO.currency_name;
              this.selectedNgo = selectedNGO;
              this.charityId  = selectedNGO.charityId;
              if (this.filterDataSubscription) this.filterDataSubscription.unsubscribe();
              this.getDateFilter();
            }
          }
        );
  }

  getDateFilter() {
    this.filterDataSubscription = this._filterSharedService
        .dateFilters$
        .subscribe(
          res => {
            this.filterData = res;
            this.filterType = res.type;
            this.eventSeriesGraphData = null;
            if (this.chart) this.chart.destroy();

            this.avgCampaign = this.avgDonation = this.avgDonations = 0;
            
            
            this.eventSubscriberList();
            this.eventList();
            this.getOnlyEventListData();
          }
        );
  }

  eventSubscriberList() {
    this.activeSubscriberLoader = true;
    this._eventService
        .eventSubscriberList(this.charityId, this.filterData)
        .pipe(finalize(() => this.activeSubscriberLoader = false))
        .subscribe(
          (res: any) => {
            this.eventSubscriberListData = res.map((val) => {
              const newData = Object.assign({}, val);
              newData.fundraiser_amount = this.formatNumber.format(val.fundraiser_amount);
              return newData;
            });
          },
          err => this.eventSubscriberListData = []
        );
  }

  getOnlyEventListData() {
    this.eventListingLoader = true;
    this._eventService
        .onlyEventList(this.charityId, this.filterData)
        .pipe(finalize(() => this.eventListingLoader = false))
        .subscribe(
          (res: any) => {
            this.eventListingData = res && res.series_listing ? res.series_listing : [];

            
  
            if (this.eventListingData && this.eventListingData.length > 0)
              this.eventListingData = res.series_listing.sort((a, b) => this.compare(a.name, b.name, true));
  
            this.sortingField = 'name';
            this.orderBy      = 'asc';
          },
          err => this.eventSeriesGraphData = null
        );
   }

  eventList() {
    this.fundraiserLoader = true;
    this._eventService
        .eventSeriesGraphData(this.charityId, this.filterData)
        .pipe(finalize(() => {
          this.fundraiserLoader = false;
          setTimeout(() => this.createChart(), 100);
        }))
        .subscribe(
          (res: any) => {
            if (!res.hasOwnProperty('data')) return;
            
            this.eventSeriesGraphData = res;
            this.eventSeriesGraphData.data.forEach((val, i) => this.datasetFlag[val.title] = true);

            if(this.eventSeriesGraphData.overall_result_dict.totalCampaign)
            this.avgCampaign = Math.round((this.eventSeriesGraphData.overall_result_dict.totalRaised / this.eventSeriesGraphData.overall_result_dict.totalCampaign));
            if(this.eventSeriesGraphData.overall_result_dict.totalDonation)
            this.avgDonation = Math.round((this.eventSeriesGraphData.overall_result_dict.totalRaised / this.eventSeriesGraphData.overall_result_dict.totalDonation));
            if(this.eventSeriesGraphData.overall_result_dict.totalCampaign)
            this.avgDonations = Math.round((this.eventSeriesGraphData.overall_result_dict.totalDonation / this.eventSeriesGraphData.overall_result_dict.totalCampaign));
          },
          err => this.eventSeriesGraphData = null
        );
  }

  createChart() {
    this.getDataSets();

    const labels = this.getLabels();

    if (this.chartData.length < 1) return;
    this.chart = new Chart('eventList', {
      type: 'bar',
      data: {
        labels,
        datasets: this.chartData
      },
      options: this._chartService.eventlistOptions
    });
    this.updateChartConfig();
  }

  getLabels() {
    if (!this.eventSeriesGraphData || !this.eventSeriesGraphData.hasOwnProperty('label')) return [];

    const labels = [];
    this.eventSeriesGraphData.label.forEach(val => labels.push(val));
    return labels;
  }

  getDataSets() {
    const yAxesConfig = this._chartService.createYaxesConfig();
    this.chartData = [];
    if (!this.eventSeriesGraphData || !this.eventSeriesGraphData.hasOwnProperty('data')) return;

    this._chartService.eventlistOptions.scales.yAxes = [];
    let order = this.eventSeriesGraphData.data.length;
    
    this.eventSeriesGraphData.data.forEach((val, i) => {
      if (!this.datasetFlag[val.title]) {
        return;
      }

      const data = {
        label: val.title,
        data: val.value,
        backgroundColor: this.chartColor[i],
        borderColor: this.chartColor[i],
        fill: true,
        barThickness: 6,
        type: '',
        borderDash: [],
        borderWidth: 0,
        lineTension: 0,
        yAxisID: '',
        order
      };

      if (
        val.type === 'line' &&
        (
          val.title === 'Total Campaigns' ||
          val.title === 'Total Donations'
        )
      ) {
        data.order            = this.chartOrder[val.title];
        data.backgroundColor  = this.chartColorLine[val.title];
        data.borderColor      = this.chartColorLine[val.title];
        data.type             = val.type;
        data.fill             = false;
        data.borderDash       = val.title === 'Total Campaigns' ? [5, 5] : [];
        data.borderColor      = '#002C45';
        data.borderWidth      = 1;
        data.yAxisID          = 'right-y-axis';

        // if (val.title === 'Total Campaigns') this.setRightYAxis('right-y-axis', 'right', false, val.title);
        this.setRightYAxis('right-y-axis', 'right', false, val.title);
        this._chartService.updateYaxesMinMax(yAxesConfig, 'right-y-axis', data?.data);
      } else {
        this.setRightYAxis('left-y-axis', 'left', true, val.title);
        data.yAxisID    = 'left-y-axis';
        data.borderColor = 'rgba(255, 255, 255, 0.9)';
        this.datasetFlag[val.title] = true;
        this._chartService.updateYaxesMinMax(yAxesConfig, 'left-y-axis', data?.data);
      }

      order--;
      this.chartData.push(data);
       
      this.datasets[val.title] = null;
    });
    this.setCustomTickConfig(yAxesConfig);
  }

  getTotalValue(title: string) {
    if (!(this.eventSeriesGraphData && this.eventSeriesGraphData.hasOwnProperty('data'))) return;

    let sum = 0;
    const data: any = this.eventSeriesGraphData.data.filter(val => val.title === title);
    if (data.length < 1) return;

    data[0].value.forEach(val => {
      if (!isNaN(val)) sum += val;
    });

    if (title === 'Total Campaigns' || title === 'Total Donations') return ' ' + sum.toLocaleString();
    let currencySymbol = this.selectedNgo.currency_name=='USD' ? ' $' : ' £';
    return currencySymbol + Math.round(sum).toLocaleString();
  }

  setRightYAxis(id: string, position: string, stacked: boolean, title:string) {
    //console.log({yaxis: title});
    this._chartService.eventlistOptions.scales.yAxes.push({
      id,
      stacked,
      type: 'linear',
      display: true,
      position,
      gridLines: {
        display: true,
        drawBorder: true,
        drawTicks: false,
        drawOnChartArea: false
      },
      ticks: {
        beginAtZero: false,
        callback: (value, index, values) => {
          let currencySymbol = this.selectedNgo.currency_name=='USD' ? ' $' : ' £';
          if (stacked) return currencySymbol + this._chartService.formatNumber(value) + ' ';
          else return ' ' + this._chartService.formatNumber(value);
        }
      },
    });
  }

  removeDataset(label: string) {   
    this.removeData[label] = false;  
    this.datasetFlag[label] = false;
    this.chart.destroy();
    this.createChart();        
    // console.log(this.chart.data.datasets);
    // this.datasets[label] = this.chart.data.datasets.filter((dataset) => (dataset.label === label))[0];
    // this.chart.data.datasets = this.chart.data.datasets.filter((dataset) => (dataset.label !== label));
    // this.chart.update();
  } 

  addDataset(label: string) {
    this.removeData[label] = true;
    this.datasetFlag[label] = true;
    // this.chart.data.datasets.push(this.datasets[label]);
    // this.chart.update();
    this.chart.destroy();
    this.createChart();      
  }

  filter(type: string, year = null, month = null) {
    const yearBtn: HTMLElement = this.modalCloseBtnYear.nativeElement;
    yearBtn.click();

    const monthBtn: HTMLElement = this.modalCloseBtnMonth.nativeElement;
    monthBtn.click();

    this.filterData.type  = type;
    this.filterData.year  = year === undefined ? null : year;
    this.filterData.month = month === undefined ? null : month;

    this._filterSharedService.changeDateFilter(this.filterData);
  }

  redirect(path: string) {
    this.router.navigate([path]);
  }

  newEvent() {
    this.router.navigate(['/events/new']);
  }

  redirectDetailsPage(ev){
    this.router.navigate(['/events/detail', ev.event_id]);
  }



sortData(sort: Sort) {
  
  this.sortingField = sort.active;
  this.orderBy      = sort.direction;
  const data = this.eventListingData && this.eventListingData.length > 0 ? this.eventListingData.slice() : [];
  if (!sort.active || sort.direction === '') return this.eventListingData = data;

  this.eventListingData = data.sort((a, b) => {
    const isAsc = sort.direction === 'asc';
    switch (sort.active) {
      case 'name': return this.compare(a.name, b.name, isAsc);
      case 'start_date': return this.compare(a.start_date, b.start_date, isAsc, true, false);
      case 'raised': return this.compare(a.raised, b.raised, isAsc);
      case 'participants': return this.compare(a.participants, b.participants, isAsc);
      case 'group_members_count': return this.compare(a.group_members_count, b.group_members_count, isAsc);
      case 'fundraisers': return this.compare(a.fundraisers, b.fundraisers, isAsc);
      case 'averages': return this.compare(a.raised_per_fund, b.raised_per_fund, isAsc);
      case 'donations': return this.compare(a.donations, b.donations, isAsc, false, true);
      case 'opt_in_per': return this.compare(a.opt_in_per, b.opt_in_per, isAsc, false, true);
      default: return 0;
    }
  });

}

compare(a: any, b: any, isAsc: boolean, isDate = false, isNumber = false) {
  if (isDate) {
    if (a == '-') a = 1970;
    if (b == '-') b = 1970;

    a = new Date(a).getTime();
    b = new Date(b).getTime();
  } else if (typeof(a) === 'string' && typeof(b) === 'string') {
    if(isNumber){
      a = +a.toLowerCase();
      b = +b.toLowerCase();      
    }else{
      a = a.toLowerCase();
      b = b.toLowerCase();
    }
  }
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

  openSubscriberDetail(_id){  
    this.router.navigate(['/subscribers/detail', _id]);
  }

  showAlertMsg(msg, type): void {
    this.alertMsg.msg   = msg;
    this.alertMsg.type  = type;

    setTimeout(() => this.alertMsg.msg = '', 3000);
  }

  ngOnDestroy() {
    if (this.charityIDSubscription) this.charityIDSubscription.unsubscribe();
    if (this.filterDataSubscription) this.filterDataSubscription.unsubscribe();
    if (this.userSubscription) this.userSubscription.unsubscribe();
    if (this.eventSubscriptionData) this.eventSubscriptionData.unsubscribe();
  }

  setCustomTickConfig (yAxesConfig: any) {
	  const maxNumSpaces = yAxesConfig?.totalTicks - 1;
    const leftYaxesConfig = this._chartService.getPreCalculatedTickConfig(yAxesConfig['left-y-axis'], maxNumSpaces);
    const rightYaxesConfig = this._chartService.getPreCalculatedTickConfig(yAxesConfig['right-y-axis'], maxNumSpaces);
    this._chartService.equalizeTickConfig(leftYaxesConfig, rightYaxesConfig);

    for (let i = 0; i < this._chartService.eventlistOptions.scales.yAxes?.length; i++) {
      const item = this._chartService.eventlistOptions.scales.yAxes[i];
      if (item?.id === 'left-y-axis') {
        this._chartService.eventlistOptions.scales.yAxes[i].ticks.min = leftYaxesConfig?.min;
        this._chartService.eventlistOptions.scales.yAxes[i].ticks.max = leftYaxesConfig?.max;
        this._chartService.eventlistOptions.scales.yAxes[i].ticks.stepSize = leftYaxesConfig?.spacing;
      } else if (item?.id === 'right-y-axis') {
        this._chartService.eventlistOptions.scales.yAxes[i].ticks.min = rightYaxesConfig?.min;
        this._chartService.eventlistOptions.scales.yAxes[i].ticks.max = rightYaxesConfig?.max;
        this._chartService.eventlistOptions.scales.yAxes[i].ticks.stepSize = rightYaxesConfig?.spacing;
      }
    }

  }

  updateChartConfig() {
    const width = this.chart?.width;
    const height = this.chart?.height;
   
    if (!width || !height) return;
        
    if (height <= 350) {
      this.chart.tooltip._options.callbacks.label = (tooltipItems, data) => {
        let label = data.datasets[tooltipItems.datasetIndex].label || '';
        
        if (label === 'Total Campaigns') {
          label = 'Campaigns: ';
        } else if (label === 'Total Donations') {
          label = 'Donations: ';
        } else {
          return '';
        }

        if (!isNaN(tooltipItems.yLabel)) label += (Math.round(tooltipItems.yLabel * 100) / 100).toLocaleString();
        else label += 0;
        return label;
      }
    } else if(height > 350) {
      this.chart.tooltip._options.callbacks.label = (tooltipItems, data) => {
        const axisId = data.datasets[tooltipItems.datasetIndex].yAxisID;
        let label = data.datasets[tooltipItems.datasetIndex].label || '';
        let currencySymbol = this.selectedNgo.currency_name=='USD' ? ' $' : ' £';
        

        if (label && axisId === 'left-y-axis') label += ': ' + currencySymbol;
        else if (label === 'Total Campaigns') label = 'Campaigns: ';
        else if (label === 'Total Donations') label = 'Donations: ';

        if (!isNaN(tooltipItems.yLabel)) label += (Math.round(tooltipItems.yLabel * 100) / 100).toLocaleString();
        else label += 0;
        return label;
      }
    }
  }
}
