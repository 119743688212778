<div class="continer-fluid">
    <div class="new-dashboard-wrapper">   
        <!-- app header -->
        <app-new-header></app-new-header>
        <!-- Side Menu -->
        <app-side-menu></app-side-menu>

        <div class="grid-wrapper">
            <div class="row no-gutters">
                <div class="col-12">
                    <div class="breadcrumbs">
                        <ul>
                            <li>
                                <a href="javascript:void(0)" (click) = "goBackToReporting()">Reporting</a>
                            </li>
                            <li class="active">
                                <a href="javascript:void(0)">Report Builder</a>
                            </li>                                   
                        </ul>
                    </div>
                </div>
                <div class="col-12">  
                    <form [formGroup]="newReportingForm" (keydown.enter)="$event.preventDefault()"  (ngSubmit)="saveNewReportData('generalSave')">  
                        <div class="heading_wrapper mbTitle">
                            <div class="page-title">
                                <!-- Report Builder -->
                                <input type="text" 
                                matTooltip="This is the report title. Double-click to edit." 
                                class="reportName" #reportName  
                                [matTooltipClass]="positionBottom.value"
                                [readonly]="!editable"                  
                                (click)="toggleClass()" 
                                (dblclick)="onDblClickEditBox($event)"
                                (keyup)="CheckValidateTitle()"                    
                                [ngClass]="isBlank? 'focusedBox' : ''"
                                formControlName="report_name" 
                                [value]="reportNameData?.report_name" />
                                <div style="padding-left: 12px; white-space: nowrap;" class="invalid-feedback-data" *ngIf="isvalidSpecialCharater">
                                    {{isValidEscape}}
                                </div>
                            </div>
                        </div>
                        <div class="btn-box">
                            <button  *ngIf = "activatedRoute?.snapshot?.queryParams?.reportId" type="button" class="error-text-md-btn-text" [matTooltipPosition]="positionTop.value" matTooltip="Clicking this will delete this report and bring you back to the Reports homepage." (click)="openDeleteConfirmDialog(activatedRoute?.snapshot?.queryParams?.reportId)">Delete</button>                           
                            <button 
                                data-toggle="modal" #addEditExportDateCloseBtnRange 
                                data-target="#datePickerModal" type="button"
                                class="accent-filled-md-btn-text darkBlue" 
                                [matTooltipPosition]="positionTop.value" 
                                [matTooltipClass]="positionTop.value"
                                matTooltip="Clicking this will prompt you to select dates, then a CSV file of your report will be downloaded to your computer.">
                                {{exportBtnName}} 
                            </button>
                            <button type="submit" class="accent-filled-md-btn-text">Save Report</button>
                        </div>
                        <div class="common-filter-wrapper form-wrapper">
                        <ul class="filter-list-options">
                            <li>
                                <div class="col-form-label"><span [matTooltipPosition]="positionTop.value" [matTooltipClass]="positionTop.value" matTooltip="This indicates whether the report is a 'Supporters' / 'Challenge' report.">Report Type</span></div>
                                <div class="wrapper">
                                    <select class="form-control reportType" formControlName="report_type" disabled>
                                        <option *ngFor="let reportType of reportTypes" [value]="reportType?.key">{{reportType?.value}}</option>
                                    </select>                                    
                                </div>
                            </li>
                            <li>
                                <div class="col-form-label"><span [matTooltipPosition]="positionTop.value" [matTooltipClass]="positionTop.value" matTooltip="This indicates which filters were chosen when building the report.">Filter by</span></div>
                                <div class="wrapper">
                                    <select class="form-control" formControlName="filter_type" (change)="onChangeReportFilter($event.target.value)">  
                                        <option value="blank" disabled="disabled" selected="selected" style="display:none;">Select filter</option>
                                        <option *ngFor="let filterData of reportFilterData; let i = index" 
                                        [value]="filterData?.key"
                                        >{{filterData?.value}}</option>
                                    </select>  
                                </div>
                            </li>
                            <li>
                                <div class="col-form-label"><span [matTooltipPosition]="positionTop.value" [matTooltipClass]="positionTop.value" matTooltip="Choose which dates you want your data to populate within">Date Range</span></div>                               
                                
                                <div class="wrapper position-relative"> 
                                    
                                        <ng-container *ngIf="dateFilterFlag; else chooseDateTemplate">
                                            <div (click)="openDatepicker()" class="dateRangeWrapper form-wrapper" data-toggle="modal" data-target="#reportsdaterangepick">
                                                 <span class="calIcon"></span>
                                                 <input type="text" class="form-control pl-28 min-wd-240" value="{{fromDate | date:'MM-dd-y'}}  to  {{toDate | date:'MM-dd-y'}}">
                                            </div>
                                        </ng-container>
                                        
                                        <ng-template #chooseDateTemplate>
                                            <div (click)="openDatepicker()" data-toggle="modal" data-target="#reportsdaterangepick" class="form-control custom-daterange min-wd-240 pl-12">
                                               Select date range<span class="calIcon"></span>  
                                            </div>
                                        </ng-template>
                                    
                                </div>   
                            </li>
                        </ul>
                        </div>
                   </form> 
                </div>
                <div class="col-12">
                    <div class="content-section">                        
                        <div class="left-content-section">
                        
                            <div class="newConditions">
                             <div class="columnsHeading mb-3">New Condition</div>
                             <div class="conditionOptHeading mb-3">
                                <select #selectedOprt (change)="onOperatorChange()" [(ngModel)]="filterCondition.operator">
                                    <option *ngFor="let operator of operatorList" [value]="operator?.value">{{ operator?.name }}</option>
                                </select> the following must be true:
                             </div>
                             <div class="form-wrapper custom-option-wrapper">
                                <div class="optionWrapper" id="style-3">
                                    <div class="optionBox mb-3" *ngFor="let condition of filterCondition?.list; let i = index; let isFirst=first" [ngClass]="{ 'mt-1': isFirst }">
                                          <div class="editCondtionText" matTooltipClass="below" matTooltip = "{{ condition?.columnKey | getColumnName: condition?.columnGroupKey: columnsData }} {{ condition?.conditionType | getConditionName: condition?.columnType }} {{ condition?.value | getValueFormat: condition?.columnType }}">
                                            {{ condition?.columnKey | getColumnName: condition?.columnGroupKey: columnsData }} {{ condition?.conditionType | getConditionName: condition?.columnType }} {{ condition?.value | getValueFormat: condition?.columnType }}
                                          </div> 
                                         <span class="editIcon" (click)="openCondition(condition, i)"></span>
                                    </div>                                                         
                                </div>                               
                                <div class="mb-3 condtionBtn">
                                    <button type="button" 
                                        (click)="openCondition(null)" 
                                        class="accent-filled-md-btn-text" 
                                        [disabled]="filterCondition?.list?.length > (maxConditionLimit - 1)" 
                                    >
                                      <span 
                                        [matTooltipPosition]="positionTop.value" 
                                        [matTooltipClass]="positionTop.value" 
                                        [matTooltip]="filterCondition?.list?.length > (maxConditionLimit -1) ? ('You cannot add more than '+ maxConditionLimit +' condition'): ''"
                                      >+New Condition</span>

                                    </button>
                                </div>
                             </div>
                            </div>  
                          
                           <!-- start accordion -->
                            <div class="row no-gutters custom-rwo-wrapper"> 
                                <div class="col-12 col-md-12">
                                    <div class="columnsHeading mb-2"><span [matTooltipPosition]="positionTop.value" [matTooltipClass]="positionTop.value" matTooltip="Click to populate and then select from a list of available columns, which are based on the Report Type." >Column Display</span></div>
                                    <div class="form-wrapper">
                                        <div class="main-accordion-wrapper">        
                                            
                                            <div class="row no-gutters">
                                                <div class="col-12 col-md-12 mb-3">
                                                    <div class="searchBox">
                                                        <input #columnSearch class="searchText form-control" [matTooltipPosition]="positionTop.value" [matTooltipClass]="positionTop.value" matTooltip="Type in this search box to quickly find a specific column to add to your report." placeholder="Search" type="search" (input)="onSearchColumns(columnSearch.value)">
                                                        <span class="searchIcon"></span>
                                                    </div>                                           
                                                </div>                                       
                                            </div>
        
                                            <div class="overlay__inner" style="top:-25%" *ngIf="allPopActionreportLoader">
                                                <div class="overlay__content">
                                                    <span class="spinner loadImg"></span>
                                                </div>
                                            </div>            
                                            
                                            <div class="accordion-wrapper mt-2" id="reportColumns">
                                                <div id="accordion" [formGroup] = "newReportingForm">
                                                    
                                                    <div class="NotFound text-center" *ngIf="showNoColumnData">
                                                        No result found
                                                    </div>
        
                                                    <div formArrayName="reportArrayColumnsData">                                             
                                                        <ng-container *ngFor="let reportColData of newReportingForm?.controls?.reportArrayColumnsData['controls']; let colIndex=index">
                                                            <div class="cardBox" *ngIf="reportColData?.controls?.reportColumns?.controls?.length">
                                                                <div [formGroupName]="colIndex"  >
                                                                    <div class="card-header-new" id="headingOne-{{colIndex}}">
                                                                        <h5 class="mb-0 panel-title">
                                                                            <div 
                                                                                class="btn-link collapsed" 
                                                                                data-toggle="collapse" 
                                                                                data-parent="#accordion" 
                                                                                aria-expanded="false"
                                                                                [attr.data-target]="'#collapse-' + colIndex">
                                                                                {{ categoryKeys[reportColData?.value?.name] }} 
                                                                                <span>({{reportColData?.value?.reportColumns | subReportLength: totalCheckBoxColumn}})</span>
                                                                            </div>                                                                   
                                                                        </h5>
                                                                    </div>                           
                                                                
                                                                    
                                                                    <div id="collapse-{{colIndex}}" class="panel-collapse collapse reportColumnChild" attr.data-parent="#headingOne-{{colIndex}}" attr.aria-labelledby="headingOne-{{colIndex}}">                                                                
                                                                    <div class="card-body-new">
                                                                        <a class="selectAll pr10" href="javascript:void(0)" (click)="selectCurrentCol(colIndex, reportColData?.value?.name)">Select All</a>
                                                                        <a class="selectAll" href="javascript:void(0)" [ngStyle] = "{'color':'#54D072'}" (click)="deSelectCurrentCol(colIndex)">Deselect All</a>
                                                                        <div class="colName">
                                                                            <ul class="columnList wd-50">     
                                                                                <ng-container formArrayName="reportColumns">                                                          
                                                                                    <li [ngClass]="reportColData.value.name==='survey_answers' ? 'surveyAnswers' : ''" *ngFor="let reportCol of reportColData.controls.reportColumns.controls; let repColumnIndex=index" [formGroupName]="repColumnIndex">
                                                                                        <label class="checkbox-container" *ngIf="reportColData.value.name!='survey_answers'">
                                                                                            <input type="checkbox" (change)="IsCheckedColumn()" formControlName="is_checked"><span class="labelName">{{reportCol.value.display_name}}</span>                                                                            
                                                                                            <span class="checkmarkBox"></span>
                                                                                        </label>
                                                                                        <label class="checkbox-container" *ngIf="reportColData.value.name==='survey_answers'">
                                                                                            <input type="checkbox" (change)="IsCheckedColumn()" formControlName="is_checked"><span [matTooltipClass]="positionBottom.value" [matTooltip]="reportCol.value.tool_tip">{{reportCol.value.display_name.split(' ').slice(0, 2).join(' ')}}</span>
                                                                                            <span class="checkmarkBox"></span>
                                                                                        </label>
                                                                                        <p *ngIf="reportColData.value.name=='survey_answers'" class="stext">{{reportCol.value.display_name.split(' ').slice(2).join(' ')}}</p>
                                                                                    
                                                                                    </li>
                                                                                    
                                                                            </ng-container>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    </div>
                
                
                                                                </div>
                                                            </div>
                                                        </ng-container>
        
                                                    </div>
                                                </div>
                                
                                            </div>
                                
                                        </div>
                                    </div>
                                </div>
                            </div> 
                           <!--End accordion -->
                        </div>
                        <div class="right-content-section">                          
                           <div class="row no-gutters mb-2">
                                <div class="col-12 col-md-12">
                                    <div class="title">
                                        <span [matTooltipPosition]="positionTop.value" [matTooltipClass]="positionTop.value" matTooltip="Below is a dynamic preview of your report based on the selections above.">Report Preview</span>
                                     </div> 
                                </div>
                                <div class="col-12 col-md-12">                                 
                                    <div class="searchBox"> 
                                            <input
                                                #searchInput
                                                type="text"
                                                class="searchText"
                                                [matTooltipPosition]="positionTop.value" [matTooltipClass]="positionTop.value" matTooltip="Type in this search box to quickly find data inside of the report preview."
                                                placeholder="Search"
                                                (keyup)="searchInReportPreview(searchInput.value,$event)"
                                                >
                                            <span class="searchIcon"></span>
                                            <span class="clearIcon" *ngIf="searchInput?.value?.length" (click) = "clearSearch()"></span>
                                    </div>
                                    <div class="emailsOnly float-right">
                                        <label class="checkbox-container">
                                            <input type="checkbox" [(ngModel)]="newReport.emailOnly" [ngModelOptions]="{standalone: true}"
                                            [checked]="newReport.emailOnly" (change)="emailOnlyToggle($event)">
                                            <span class="labelName">Email Only</span>
                                            <span class="checkmarkBox"></span>
                                        </label>
                                    </div> 
                                </div>                 
                           </div> 
                           <div class="grid-content-wrapper">
                             <div class="position-relative">
                                <div class="overlay__inner" style="top:60px" *ngIf="reportLoader">
                                    <div class="overlay__content">
                                        <span class="spinner loadImg"></span>
                                    </div>
                                </div>
                             </div>  
                            <div class="table-responsive">   
                                <table class="report-list table table-striped">
                                    <thead>
                                        <tr>
                                            <th class="cmn" [ngClass]="{'emailHeading' : headingName == 'Email', 'fullNamwHeading' : headingName == 'Full Name', 'CampaignHeading' : headingName == 'Campaign ID', 'CampaignUrlHeading' : headingName == 'Campaign URL'}" *ngFor="let headingName of checkedDisplayName; let i = index">
                                               
                                                <ng-container *ngIf="headingName?.indexOf('Question') !== -1; else defaultColumn">
                                                    {{headingName?.split(' ')?.slice(0, 2)?.join(' ')}}
                                                </ng-container>
                                                <ng-template #defaultColumn>
                                                    {{headingName}}
                                                </ng-template>
                                                <span class="dots" *ngIf = "['Updated Date','Birthday ','Messenger Opt in Date','Postal Consent Date','Email Consent Date','Phone Number Consent Date','SMS - Consent Date','Mission Story Consent Date','Campaign Start Date','Campaign End Date','Charge Time PT','Donation Date','Date of Acquisition','Date of Incentive Claim','Unsubscribed Date']?.includes(headingName)"></span>
                                                <div class="dropdown sortingBox" *ngIf = "['Updated Date','Birthday ','Messenger Opt in Date','Postal Consent Date','Email Consent Date','Phone Number Consent Date','SMS - Consent Date','Mission Story Consent Date','Campaign Start Date','Campaign End Date','Charge Time PT','Donation Date','Date of Acquisition','Date of Incentive Claim','Unsubscribed Date']?.includes(headingName)">
                                                    <span class="dots" data-toggle="dropdown" aria-haspopup="true"></span>
                                                    <div class="dropdown-menu w-open">
                                                        <button class="dropdown-item" (click) = "reportSortOrder('asc',headingName)">
                                                            Sort Ascending
                                                        </button>
                                
                                                        <button class="dropdown-item" (click) = "reportSortOrder('desc',headingName)">
                                                            Sort Decending
                                                        </button>
                                
                                                        <!-- <button class="dropdown-item">
                                                            Hide Column
                                                        </button> -->
                                                        <a href="javascript:void(0)" class="dropdown-item" 
                                                        (click)="openCondition(null)" [class.disabled]="filterCondition?.list?.length > (maxConditionLimit - 1)">
                                                            + New Condition
                                                        </a>
                                                    </div>
                                                </div>
                                            </th>      
                                        </tr>
                                    </thead>
                                    <ng-container *ngIf="previewDatas?.length > 0">
                                        <tbody>
                                            <tr *ngFor="let prevData of previewDatas | paginate: config; let i = index">
                                                <td class="cmn" [ngClass]="{'fullName': k == 'full_name'}" *ngFor="let k of keysNameData">
                                                    <ng-container *ngIf="k =='product'; else defaultData">
                                                        <div class="product" [ngClass]="{'skyblue': prevData[k] == 'direct', 'orange' : prevData[k] == 'Challenge'}">
                                                            {{prevData[k]}} 
                                                        </div>
                                                    </ng-container>
                                                    <ng-template #defaultData>
                                                        <ng-container *ngIf="prevData[k]!='null'">
                                                            <div *ngIf="prevData[k] && prevData[k]?.length >= 40" [matTooltipClass]="positionBottom.value" [matTooltip]="prevData[k]">{{prevData[k]}}</div>
                                                            <div *ngIf="prevData[k] && prevData[k]?.length < 40">{{prevData[k]}}</div>
                                                        </ng-container>                                                                   
                                                    </ng-template>                                                                
                                                </td>                                                                                                                                                              
                                            </tr>
                                        </tbody>
                                    </ng-container>
                                </table>
                                
                                 
                            </div>
                               <div class="newGuPagination float-right" *ngIf="previewDatas?.length>0">
                                <div class="totalCounts">Displaying {{currentPageCount}} of {{totalRecords}} Results</div>
                                <pagination-controls [directionLinks]="false" (pageChange)="pageChanged($event)">
                                </pagination-controls>
                               </div>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- add edit export date picker Modal - STARTS -->

<div class="modal above fade exportDatePicker" id="datePickerModal" #closeOpenCalendarAlltimeExport role="dialog">
    <div class="modal-dialog modal-dialog-centered custom-dialog-modal modal-lg" role="document">
        <div class="modal-content m-2">

            <div class="modal-body">
                <div class="form-wrapper">
                    <!-- For Table Data -->
                    <div class="row no-gutters exportCalendarOnModal">
                        <div class="col-12">
                            <button type="button" class="closeDatePicker close" 
                                data-dismiss="modal">&times;</button>
                            <h2>Export CSV</h2>
                            <div class="captionText">
                                <p>Choose a date range. Or, click the "All Time" button to export all of your data.</p>
                            </div>
                        </div>
                        <div class="dRangeBoxForModalExport BSDatePicker">
                            <div class="from common">
                                <div class="exportDateTime form-control">{{startDate | date:'MM-dd-y' }} to {{endDate |
                                    date:'MM-dd-y'}}</div>
                                <span class="calIcon"></span>
                            </div>
                            <div class="button-wrapper"><button type="button" class="accent-filled-md-btn-text"
                                    (click)="addEditExportAllData()">All Time</button></div>

                            <bs-daterangepicker-inline [bsValue]="bsInlineRangeValue"
                                [maxDate]="bsMaxDate"
                                (bsValueChange)="onDateRangeChange($event)"
                                [bsConfig]="{ showWeekNumbers: false }">
                            </bs-daterangepicker-inline>
                            <div class="button-wrapper exportBtn">
                                <button type="button" class="accent-filled-md-btn-text" (click)="onExport()">
                                    Export 
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- add edit export date picker Modal - ENDS -->

<!-- Before export add report confirm Modal - STARTS -->
<div class="modal above fade confirmBoxwrapper" id="beforeExportSaveReportConfirmModal" role="dialog" style="opacity: 1;" *ngIf = "showExportSaveConfirmPopUp" [ngStyle]="{'display':'block'}">
    <div class="modal-dialog modal-dialog-centered custom-dialog-modal modal-md" role="document">
        <div class="modal-content m-2 pbt-30">
            <button type="button" class="closeDatePicker close" #beforeExportSaveConfirmModel data-dismiss="modal"></button>
            <div class="modal-body">
                <div class="form-wrapper">
                    <!-- For Table Data -->
                    <div class="row no-gutters custom-rwo-wrapper text-center"> 
                        <div class="col-12">
                            <h6 class="mb-4">Would you like to save this report?</h6>
                        </div>
                        <div class="col-12 col-md-12 text-center">                           
                            <button type="button" class="mono-dark-filled-md-btn-text" (click)="beforeExportSaveReportData('yes')" data-dismiss="modal">Yes</button>
                            <button type="button" class="accent-filled-md-btn-text" (click)="beforeExportSaveReportData('no')">No </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Before export add report confirm Modal - ENDS -->

<!--Choose Date Range Picker Modal-->
<div class="modal dateRangePicker" id="reportsdaterangepick" (click)="stopCloseCalendar($event)" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content">

        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Select Range</h4>
          <button type="button" #modalCloseBtnRangeFilterData (click)="closeOpenCalendarfilterData()" class="close" data-dismiss="modal">&times;</button>
        </div>

        <!-- Modal body -->
        <div class="modal-body">
            <input
                #ngxDatePicker
                type="text"
                matInput
                ngxDaterangepickerMd
                [linkedCalendars]="true"
                [locale]="locale"
                startKey="start"
                endKey="end"
                [maxDate]="maxDate"
                [(ngModel)]="selected"
                [alwaysShowCalendars]="true"
                [showClearButton]="true"
                class="datepicker-calendar-icon form-control"
                (change)="ngDateChange($event)"
                placeholder="Start Date to End Date"
                name="daterange"/>       
        </div>
        <!-- Modal footer -->
        <!-- <div class="modal-footer">
          <button type="button" class="btn btn-danger" (click)="onFilterFormClear()">Close</button>
        </div> -->
      </div>
    </div>
</div>
<ng-container *ngIf="alertMsg.msg !== ''">
    <app-alert [message]="alertMsg"></app-alert>
</ng-container>
<div [ngClass]="conditionOverlay?'overlay-builder':''">

</div>